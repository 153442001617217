<template>
  <div>
    <ActiveGymComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import ActiveGymComp from "@/components/desktop/Activities/ActiveGymComp";

  export default {
    name: 'ActiveGym',
    props: ['mobile'],
    components: {
      ActiveGymComp,
    },
  }
</script>
